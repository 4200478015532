import controller_0 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/styles/live.css';
import controller_1 from '@symfony/ux-vue/dist/render_controller.js';
import { Controller } from '@hotwired/stimulus';
export default {
  'symfony--ux-chartjs--chart': class extends Controller {
      constructor(context) {
          super(context);
          this.__stimulusLazyController = true;
      }
      initialize() {
          if (this.application.controllers.find((controller) => {
              return controller.identifier === this.identifier && controller.__stimulusLazyController;
          })) {
              return;
          }
          import('@symfony/ux-chartjs/dist/controller.js').then((controller) => {
              this.application.register(this.identifier, controller.default);
          });
      }
  },
  'live': controller_0,
  'symfony--ux-vue--vue': controller_1,
};