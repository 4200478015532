import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    connect() {

        // Tooltips Bootstrap
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })

        // Champs obligatoires forms
        let requiredField = Object.values(document.querySelectorAll('.required'));
        requiredField.map(element => {
            element.innerHTML = element.textContent + ' <small class="grape-2 fw-bold">Obligatoire</small>';
        });
    }

}



  